import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);
let router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      component: () => import(/* webpackChunkName: "home" */ "../containers/home/home.vue"),
    },
    {
      path: "/register",
      component: () => import(/* webpackChunkName: "register" */ "../containers/register/index.vue"),
    },
  ]
})

export default router;
